export default {
  namespaced: true,
  state: {
    ev: null,
  },
  getters: {},
  mutations: {
    setEvent(state, ev) {
      state.ev = ev;
    },
  },
  actions: {
    updateEvent({ commit }, ev) {
      commit("setEvent", ev);
    },
  },
};
