import Vue from "vue";
import Vuex from "vuex";
import Auth from "./auth";
import General from "./general";
import Event from "./event";
import Manager from "./manager";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    General,
    Event,
    Manager,
  },
});
