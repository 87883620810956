import api from "@/api";

export default {
  namespaced: true,
  state: {
    groups: null,
    spaces: null,
    bookingJob: null,
    managers: [],
    clients: {},
  },
  getters: {
    isAdmin(state) {
      return (slug) => {
        const item = state.managers.find(
          (manager) => manager.space_location.slug === slug
        );
        if (item) {
          return item.is_main_admin;
        }
        return false;
      };
    },
  },
  mutations: {
    SET_GROUPS(state, val) {
      state.groups = val;
    },
    SET_SPACES(state, val) {
      state.spaces = val;
    },
    SET_BOOKING_JOB(state, value) {
      state.bookingJob = value;
    },
    SET_MANAGERS(state, value) {
      state.managers = value;
    },
    SET_CLIENTS(state, data) {
      state.clients = data.clients;
    },
  },
  actions: {
    getGroups(context) {
      return new Promise((resolve) => {
        if (context.state.groups !== null) {
          resolve();
          return;
        }
        api.Util.axios.get("/groups/").then((response) => {
          context.commit("SET_GROUPS", response.data.results);
          resolve();
        });
      });
    },
    getSpaces(context) {
      return new Promise((resolve) => {
        if (context.state.spaces !== null) {
          resolve();
          return;
        }
        api.Manager.axios.get("/Spaces/").then((response) => {
          context.commit("SET_SPACES", response.data.results);
          resolve();
        });
      });
    },
    exportBookings(context, spaceId) {
      return new Promise((resolve, reject) => {
        api.Manager.axios
          .get(`/exportData/bookings?space_id=${spaceId}`)
          .then((response) => {
            if (response.data.job_id) {
              context.commit("SET_BOOKING_JOB", response.data.job_id);
              api.Util.getTaskResult(response.data.job_id).then((data) => {
                resolve(data);
                context.commit("SET_BOOKING_JOB", null);
              });
              return;
            }
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getManager(context) {
      return api.Util.axios.get("/managers/").then((response) => {
        context.commit("SET_MANAGERS", response.data.results);
      });
    },
    loadClients(context) {
      return api.clients().then((response) => {
        context.commit("SET_CLIENTS", {
          clients: response.data.results,
        });
      });
    },
  },
};
