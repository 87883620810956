import NotificationMixin from "@/mixins/Notification.js";
import api from "@/api";

function showMessageWithServiceWorker(message, sampleItem) {
  window.navigator.serviceWorker.ready.then((registration) => {
    registration.showNotification(message, {
      body: sampleItem.message,
      data: sampleItem,
      badge: "https://tizi.possumk.xyz/logo.png",
      icon: "https://tizi.possumk.xyz/logo.png",
      vibrate: [200, 200, 200],
    });
  });
}

function showMessage(message, sampleItem) {
  const notif = new Notification(message, {
    badge: "https://tizi.possumk.xyz/logo.png",
    icon: "https://tizi.possumk.xyz/logo.png",
    body: sampleItem.message,
    data: sampleItem,
    requireInteraction: true,
    vibrate: [200, 200, 200],
  });
  notif.onclick = (event) => {
    const data = event.target.data;
    if (!data) {
      return;
    }
    const params = NotificationMixin.getLinkParams(data);
    console.log(params);
    window.vueInstance.$router.push(params);
  };
  return notif;
}

function notify(message, sampleItem) {
  if (!("Notification" in window)) {
    return;
  }

  if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    // we have already request on App.vue
    try {
      showMessage(message, sampleItem);
    } catch (err) {
      console.error("Error with showMessage:", err);
      try {
        showMessageWithServiceWorker(message, sampleItem);
      } catch (err2) {
        console.error("Error with showMessageWithServiceWorker:", err);
      }
    }
  }
}

export default {
  namespaced: true,
  state: {
    loading: true,
    loadingNotifications: true,
    notifications: [],
    hideNavigation: true,
    spaces: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getSpaceBySlug(state) {
      return (slug) => {
        for (const space of state.spaces) {
          if (space.slug === slug) {
            return space;
          }
        }
        return null;
      };
    },
    getUnseen(state) {
      var d = [];
      for (var i = 0; i < state.notifications.length; i++) {
        var item = state.notifications[i];
        if (!item.seen) {
          d.push(item);
        }
      }
      return d;
    },
  },
  mutations: {
    SET_LOADING_NOTIFICATIONS(state, val) {
      state.loadingNotifications = val;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setNotifications(state, data) {
      const newItems = data.filter((item) => !item.seen);
      const currentIds = state.notifications.map((item) => item.id);
      const incomingIds = newItems.map((item) => item.id);
      const newIds = incomingIds.filter(
        (item) => currentIds.indexOf(item) === -1
      );
      if (newIds.length) {
        const sampleId = newIds[newIds.length - 1];
        const sampleItem = data.find((item) => item.id === sampleId);
        notify(`You have ${newIds.length} new notifications.`, sampleItem);
      }
      state.notifications = data;
    },
    setHideNavigation(state, val) {
      state.hideNavigation = val;
    },
    SET_SPACES(state, data) {
      state.spaces = data;
    },
  },
  actions: {
    updateLoading({ commit }, loading) {
      commit("setLoading", loading);
    },
    getNotifications(context, pageData) {
      if (!pageData) {
        pageData = {};
      }
      const authData = context.rootState.Auth.authData;
      if (!authData || !authData.email) {
        return;
      }
      const page = pageData.page || 1;
      const pageSize = pageData.pageSize || 10;
      const url = `/notifications/?page=${page}&page_size=${pageSize}`;
      context.commit("SET_LOADING_NOTIFICATIONS", true);
      return new Promise((resolve, reject) => {
        api.Util.axios
          .get(url)
          .then(({ data }) => {
            context.commit("setNotifications", data.results);
            resolve(data);
          })
          .catch((err) => {
            console.error("Error getting notifications:", err);
            reject(err);
          })
          .finally(() => {
            context.commit("SET_LOADING_NOTIFICATIONS", false);
          });
      });
    },
    markAllNotificationsRead() {
      return api.Util.axios.get("/notifications/mark_all/");
    },
    updateHideNavigation(context, val) {
      context.commit("setHideNavigation", val);
    },
    loadSpaces(context) {
      return new Promise((resolve) => {
        api.Util.axios.get("/space-locations/").then((response) => {
          context.commit("SET_SPACES", response.data.results);
          resolve();
        });
      });
    },
  },
};
