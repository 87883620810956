import BaseAxios from "axios";

function createAxiosInstance(baseURL = "/api") {
  const axios = BaseAxios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("tiziAuthToken");
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error?.response?.status === 401) {
        // we are only dealing with 401 errors here
        localStorage.removeItem("tiziAuthToken");
        localStorage.setItem("path", window.location.href);
        localStorage.setItem("fullPath", window.location.href);
        window.location.reload();
      }

      return Promise.reject(error);
    }
  );

  return axios;
}

const axios = createAxiosInstance();

function getTaskResult(taskId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/redisResult/${taskId}`)
      .then((response) => {
        const state = response.data.state;
        if (state === "FAILURE") {
          reject(response.data.traceback);
          return;
        }
        if (state === "SUCCESS") {
          const result = response.data.result;
          resolve(result);
          return;
        }
        setTimeout(() => {
          resolve(getTaskResult(taskId));
        }, 1000);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export default {
  BaseAxios,
  axios,
  createAxiosInstance,
  getTaskResult,
};
