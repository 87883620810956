import Util from "./util";

const axios = Util.createAxiosInstance("/tizi/api/");

export default {
  getBookingsBetween(slug, startDate, endDate) {
    var data = {
      start_date: startDate,
      end_date: endDate,
      slug: slug,
    };
    return axios.get("schedules2/bookings-between-dates/", { params: data });
  },
};
