const Admin = () =>
  import(/* webpackChunkName: 'Admin' */ "@/views/Manager/Admin/Index.vue");
const AdminAdd = () =>
  import(/* webpackChunkName: 'Admin' */ "@/views/Manager/Admin/Add.vue");
const AdminList = () =>
  import(/* webpackChunkName: 'Admin' */ "@/views/Manager/Admin/List.vue");
const Admins = () =>
  import(/* webpackChunkName: 'Admins' */ "@/views/Manager/Admins.vue");
const ArtistBlacklist = () =>
  import(
    /* webpackChunkName: 'ArtistBlacklist' */ "@/views/Manager/ArtistBlacklist.vue"
  );
const Billing = () =>
  import(/* webpackChunkName: 'Billing' */ "@/views/Manager/Billing.vue");
const BillingPaid = () =>
  import(
    /* webpackChunkName: 'BillingPaid' */ "@/views/Manager/BillingPaid.vue"
  );
const BillingUnpaid = () =>
  import(
    /* webpackChunkName: 'BillingUnpaid' */ "@/views/Manager/BillingUnpaid.vue"
  );
const Bookings = () =>
  import(/* webpackChunkName: 'Bookings' */ "@/views/Manager/Bookings.vue");
const Clients = () =>
  import(/* webpackChunkName: 'Clients' */ "@/views/Manager/Clients.vue");
const Company = () =>
  import(/* webpackChunkName: 'Company' */ "@/views/Manager/Company.vue");
const Dashboard = () =>
  import(/* webpackChunkName: 'Dashboard' */ "@/views/Manager/Dashboard.vue");
const EquipmentList = () =>
  import(
    /* webpackChunkName: 'EquipmentList' */ "@/views/Manager/EquipmentList.vue"
  );
const LandingChooser = () =>
  import(
    /* webpackChunkName: 'LandingChooser' */ "@/views/Manager/LandingChooser.vue"
  );
// const OverallSettings = () => import(/* webpackChunkName: 'OverallSettings' */ '@/views/Manager/OverallSettings.vue')
// const ListSpaces = () => import(/* webpackChunkName: 'ListSpaces' */ '@/views/Manager/ListSpaces.vue')
const PaymentPolicies = () =>
  import(
    /* webpackChunkName: 'PaymentPolicies' */ "@/views/Manager/PaymentPolicies.vue"
  );
const RecurringBookings = () =>
  import(
    /* webpackChunkName: 'RecurringBookings' */ "@/views/Manager/RecurringBookings.vue"
  );
const Products = () =>
  import(
    /* webpackChunkName: 'Products' */ "@/views/Manager/products/Products.vue"
  );
const ScheduleItem = () =>
  import(
    /* webpackChunkName: 'ScheduleItem' */ "@/views/Manager/ScheduleItem.vue"
  );
const Settings = () =>
  import(/* webpackChunkName: 'Settings' */ "@/views/Manager/Settings.vue");
const ShowBookings = () =>
  import(
    /* webpackChunkName: 'ShowBookings' */ "@/views/Manager/ShowBookings.vue"
  );
const Space = () =>
  import(/* webpackChunkName: 'Space' */ "@/views/Manager/Space.vue");
const SpaceSettings = () =>
  import(
    /* webpackChunkName: 'SpaceSettings' */ "@/views/Manager/SpaceSettings.vue"
  );
const SpaceIncome = () =>
  import(
    /* webpackChunkName: 'SpaceIncome' */ "@/views/Manager/SpaceIncome.vue"
  );

const routes = [
  {
    path: "",
    component: LandingChooser,
    name: "Manager-LandingChooser",
    children: [
      {
        path: "all",
        component: Company,
        name: "Company",
        children: [
          {
            path: "approve-bookings",
            component: () =>
              import(
                /* webpackChunkName: 'ApproveBookings' */ "@/views/Manager/ApproveBookings.vue"
              ),
            name: "ApproveBookings",
          },
          {
            path: "dash",
            component: Dashboard,
            name: "Manager-Dashboard",
          },
          {
            path: "schedule-item/:ID",
            component: ScheduleItem,
            name: "ScheduleItem",
          },
          {
            path: "clients/:ID",
            component: Clients,
            name: "SingleClients",
          },
          {
            path: "clients",
            component: Clients,
            name: "Clients",
          },
          {
            path: "admins",
            component: Admins,
            name: "Admins",
          },
          {
            path: "payment-policies",
            component: PaymentPolicies,
            name: "PaymentPolicies",
          },
          {
            path: "settings",
            component: Settings,
            name: "Settings",
          },
          {
            path: "artist-blacklist",
            component: ArtistBlacklist,
            name: "ArtistBlacklist",
          },
          {
            path: "billing",
            component: Billing,
            meta: { hiddenViewName: "billing" },
            name: "Billing",
            children: [
              {
                path: "paid",
                component: BillingPaid,
                meta: { hiddenViewName: "billing.paid" },
                name: "BillingPaid",
              },
              {
                path: "unpaid",
                component: BillingUnpaid,
                meta: { hiddenViewName: "billing.unpaid" },
                name: "BillingUnpaid",
              },
            ],
          },
        ],
      },
      {
        path: ":slug",
        component: Space,
        name: "Space",
        children: [
          {
            path: "a",
            component: Admin,
            name: "Admin",
            children: [
              {
                path: "add",
                component: AdminAdd,
                name: "AdminAdd",
              },
              {
                path: "list",
                component: AdminList,
                name: "AdminList",
              },
            ],
          },
          {
            path: "b",
            component: ShowBookings,
            name: "ShowBookings",
          },
          {
            path: "s",
            component: SpaceSettings,
            name: "SpaceSettings",
          },
          {
            path: "rb",
            component: RecurringBookings,
            name: "RecurringBookings",
          },
          {
            path: "rb/:ID",
            component: RecurringBookings,
            name: "RecurringBooking",
          },
          {
            path: "products",
            component: Products,
            name: "Products",
          },
          {
            path: "products/:id",
            component: Products,
            name: "Product",
          },
          {
            path: "si",
            component: SpaceIncome,
            name: "SpaceIncome",
          },
          {
            path: "p",
            component: () =>
              import(
                /* webpackChunkName: 'Photos' */ "@/views/Manager/Photos.vue"
              ),
            name: "Manager-Photos",
          },
          {
            path: "e",
            component: EquipmentList,
            name: "EquipmentList",
          },
          {
            path: "Bookings",
            component: Bookings,
            name: "Bookings",
          },
        ],
      },
    ],
  },
];
export default routes;
