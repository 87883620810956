export default {
  getLinkParams(notif) {
    if (notif.type?.name === "Schedule") {
      let name = "UserBookingsID";
      if (notif.for_manager) {
        // name = "ScheduleItem";
        name = "ApproveBookings";
      }
      return {
        name: name,
        params: {
          ID: notif.obj_id,
        },
      };
    }
    if (!notif.params) {
      return;
    }
    var data = JSON.parse(notif.params);
    if (data.type) {
      var p = {};
      if (
        data.type === "Payment" ||
        data.type === "Booking" ||
        data.type === "Schedule"
      ) {
        p.name = "UserBookings";
        p.query = {
          s_id: data.id,
        };
      }
      return p;
    }
  },
};
