// import BaseAxios from 'axios'
import Util from "./util";
import SpaceLocations from "./space_locations";
import Schedules from "./schedules";
import Manager from "./manager";

const axios = Util.createAxiosInstance("/");

export default {
  axios,
  Manager,
  SpaceLocations,
  Schedules,
  Util,
  login(data) {
    return Util.BaseAxios.post("/api/token/obtain/", data);
  },
  socialLogin(data) {
    return axios.post("/api/socialLogin", data);
  },
  clients() {
    return axios.get(`/api/clients/`);
  },
  log(msg, level) {
    const obj = { msg: msg };
    if (!level) {
      level = "debug";
    }
    obj.level = level;
    return axios.post("/api/log/", obj);
  },
};
