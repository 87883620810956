import Util from "./util";

const axios = Util.createAxiosInstance("/tizi/api/space-locations");

export default {
  reviews(spaceLocationId) {
    return axios.get(`/${spaceLocationId}/reviews/`);
  },
  slug(slug) {
    return axios.get(`/slug/?slug=${slug}`);
  },
  recurrences(spaceLocationId) {
    return axios.get(`/${spaceLocationId}/recurrences/`);
  },
  equipment(spaceLocationId) {
    return axios.get(`/${spaceLocationId}/equipment/`);
  },
  calendarConfig(spaceLocationId) {
    return axios.get(`/${spaceLocationId}/calendar_config/`);
  },
};
