/* eslint-disable camelcase, */
import api from "@/api";
import jQuery from "jquery";

export const MyComponent = {
  methods: {
    get24HourTime: function (time) {
      var time_string = "am";
      if (time > 12) {
        time_string = "pm";
        time = time % 12;
      }
      return time + time_string;
    },
    fetchSpaceLocationData: function (callback) {
      var slug = this.$route.params.slug;
      if (!slug) {
        alert(
          "Unable to fetch data for rehearsal space since slug does not exist."
        );
        return;
      }

      this.$store.dispatch("General/updateLoading", true);

      api.SpaceLocations.slug(slug).then((response) => {
        this.space = response.data;
        this.$store.dispatch("General/updateLoading", false);
        if (callback) {
          callback();
        }
      });
    },

    getReviewsForSpaceLocation: function () {
      var SpaceID = this.space.id;
      api.SpaceLocations.reviews(SpaceID).then((response) => {
        this.reviews = response.data;
      });
    },

    createCalendarForSelector: function (
      selector,
      days,
      dayClickCallable,
      space
    ) {
      var c = jQuery(selector);
      var minTime = "06:00:00";
      var maxTime = "23:59:00";
      if (space) {
        minTime = space.start_hour + ":00:00";
        maxTime = space.end_hour + ":00:00";
      }
      this.$fullcalendar().then(() => {
        c.fullCalendar({
          header: {
            left: "next, today",
            right: "",
          },
          views: {
            agendaDay: {
              buttonText: "View Day",
            },
            listWeek: {
              buttonText: "Week List View",
            },
            agendaBig: {
              type: "agenda",
              columnFormat: "ddd D",
              titleFormat: "ddd D",
              duration: { days: days },
              buttonText: "Week",
            },
            agendaFourDay: {
              type: "agenda",
              // columnFormat: 'ddd',
              columnFormat: "dddd",
              buttonText: "4 day",
              duration: { days: 4 },
            },
          },
          dayClick: dayClickCallable,
          defaultView: "agendaBig",
          slotDuration: "01:00:00",
          minTime: minTime,
          maxTime: maxTime,
          slotEventOverlap: false,
          allDaySlot: false,
          defaultDate: new Date(),
          navLinks: true, // can click day/week names to navigate views
          eventLimit: true, // allow "more" link when too many events
          editable: false,
        });
      });
    },

    isNonRepetitiveName: function (space) {
      var name1 = space.name;
      var name2 = "";
      if (space.space_company !== undefined) {
        name2 = space.space_company.company_name;
      }
      if (name1 === name2) {
        return false;
      }
      return true;
    },
  },
};
