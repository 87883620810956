<script>
import Loader from "vue-element-loading";
import Vue from "vue";

import api from "@/api";
import { MyComponent } from "@/components/MyComponent.js";
import Navigation from "@/components/Navigation.vue";

export default {
  name: "app",
  mixins: [MyComponent],
  components: { Navigation, Loader },
  data: function () {
    var hasNotifications = false;
    var canNotify = false;
    if ("Notification" in window) {
      canNotify = true;
      hasNotifications = Notification.permission === "granted";
    }
    return {
      initialLoaded: false,
      alert_message: "",
      popupMessage: null,
      hideNotificationButton: false,
      canNotify: canNotify,
      hasNotifications: hasNotifications,
    };
  },
  computed: {
    ready() {
      return this.$store.state.Auth.loginInitialized;
    },
    loggedIn() {
      return this.$store.state.Auth.loggedIn;
    },
    loading() {
      return this.$store.getters["General/getLoading"];
    },
  },
  methods: {
    loadNotificationsWithTimeout() {
      this.$store.dispatch("General/getNotifications").finally(() => {
        setTimeout(() => {
          this.loadNotificationsWithTimeout;
        }, 4000);
      });
    },
    showNotifications() {
      if (!("Notification" in window)) {
        return;
      }
      if (!["granted", "denied"].includes(Notification.permission)) {
        window.Notification.requestPermission().then((responseText) => {
          if (responseText === "granted") {
            this.hasNotifications = true;
          }
          this.hideNotificationButton = true;
        });
      }
    },
    checkPostLogin() {
      return api.Util.axios.get("/urgentActions/").then(({ data }) => {
        if (data.location) {
          window.location.href = data.location;
        }
      });
    },
    logout: function () {
      this.$store.dispatch("General/updateLoading", 1);
      localStorage.removeItem("tiziAuthToken");
      this.$store.dispatch("Auth/updateLoggedIn", false);
      window.location.reload();
    },
    async checkIfLoggedIn() {
      if (!localStorage.getItem("tiziAuthToken")) {
        await this.$store.dispatch("Auth/updateLoginInitialized", true);
        return;
      }
      this.$store.dispatch("General/updateLoading", true);
      await this.$store
        .dispatch("Auth/tryLogin")
        .then(async () => {
          this.$store.dispatch("General/updateLoading", false);
          await this.$store.dispatch("General/getNotifications");
          this.checkPostLogin()
            .then(() => {
              this.$store.dispatch("Auth/updateLoginInitialized", true);
            })
            .catch((err) => {
              console.error(err);
              this.$store.dispatch("Auth/updateLoginInitialized", true);
            });
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("General/updateLoading", false);
          this.$store.dispatch("Auth/updateLoginInitialized", true);
        });
    },
    setupLogging() {
      window.onerror = function (msg, file, line, col, error) {
        const obj = { msg, line, col, file, error, level: "error" };
        console.error(obj);
        api.axios.post("/api/log/", obj);
      };
      Vue.config.errorHandler = function (err, vm, info) {
        const error = {
          msg: err.message,
          line: err.lineNumber,
          col: err.columnNumber,
          file: err.fileName,
        };
        const obj = { ...error, info, level: "error" };
        api.axios.post("/api/log/", obj);
        throw err;
      };
    },
  },
  async created() {
    this.initialLoaded = false;

    this.setupLogging();
    api.axios.post("/api/log/", { msg: "New Connection" });

    const promises = [];
    try {
      promises.push(this.$store.dispatch("General/loadSpaces"));
      await Promise.all(promises);
    } catch (e) {
      console.log("Error with promises");
    }
    this.loadNotificationsWithTimeout();
    await this.checkIfLoggedIn();
    this.initialLoaded = true;
  },
};
</script>

<template>
  <div id="app">
    <navigation :logout="logout" :alert_message="alert_message" />
    <Loader :active="!ready" />

    <div
      class="container py-2 mt-2"
      v-if="canNotify && !hasNotifications && !hideNotificationButton"
    >
      <div class="row">
        <div class="col">
          <button class="btn-primary btn" @click.prevent="showNotifications">
            Click here to enable notifications in the background...
          </button>
        </div>
      </div>
    </div>

    <template v-if="ready">
      <div
        id="popupMessage"
        class="wow slideInTop hide"
        data-wow-delay="0.5"
        v-if="popupMessage != null"
      >
        <div class="container">
          <div class="row justify-content-center text-center">
            <div
              class="mycol col-8 col-md-4 justify-content-center text-center text-white bg-success"
            >
              {{ popupMessage }}
            </div>
          </div>
        </div>
      </div>

      <Loader
        style="position: fixed; margin-top: 60px"
        :active="loading"
        spinner="ring"
        color="#64B963"
      />

      <router-view :logout="logout" v-if="initialLoaded" />
    </template>

    <footer>
      <div id="copyright">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="site-info pull-left">
                <p>
                  All copyrights reserved &copy; 2022 - <br />
                  Designed by
                  <a href="https://web.facebook.com/GeorgeNyoro" rel="nofollow"
                    >George Nyoro</a
                  >
                </p>
              </div>
              <div class="bottom-social-icons social-icon pull-right">
                <a
                  class="facebook"
                  target="_blank"
                  href="https://web.facebook.com/geonyoro"
                  ><i class="fab fa-facebook"></i
                ></a>
                <a
                  class="twitter"
                  target="_blank"
                  href="https://twitter.com/NyoroGeorge"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a
                  class="linkedin"
                  target="_blank"
                  href="https://www.linkedin.com/GeorgeNyoro"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
$top: 60px;
@import url("https://fonts.googleapis.com/css?family=Lato:300");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap");

h1,
h2,
h3,
h4,
h5 {
  font-family: lato, sans-serif;
}
a {
  color: rgb(76, 175, 80);
}
#app {
  font-size: 14px;
  background: #f6f6f6;
  min-height: 100vh;
  position: relative;
  padding-bottom: 140px;
  padding-top: $top;
  .router-link-active {
    color: white;
    background: #4caf50;
    background: linear-gradient(90deg, #4caf50, #009d6b);
  }
  .fc-event {
    border: 1px solid #ccc;
    background: #444;
    &.meow {
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    }
    &.meow-1 {
      background: linear-gradient(45deg, #0e330a, #0f3b42);
    }
    &.meow-2 {
      background: linear-gradient(45deg, #3f2b13, #363f13);
    }
    &.meow-3 {
      background: linear-gradient(45deg, #363f13, #153f13);
    }
    &.meow-4 {
      background: linear-gradient(45deg, #153f13, #13383f);
    }
    &.meow-5 {
      background: linear-gradient(45deg, #13383f, #21133f);
    }
    .fc-bg {
      opacity: 0.2;
    }
  }
}
footer {
  background: white;
  border-top: 1px solid #eee;
  position: absolute;
  bottom: 0px;
  width: 100%;
  #copyright .container {
    padding-top: 10px;
  }
  .bottom-social-icons a {
    border: 1px solid rgb(241, 241, 241);
    padding: 5px 10px;
    background: #666;
    color: white;
    &:hover {
      color: inherit;
      background: #ccc;
    }
  }
  padding-bottom: 10px;
}

#popupMessage {
  position: fixed;
  top: 0px;
  height: 100px;
  z-index: 3;
  width: 100%;
  border-radius: 3px;
  .mycol {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}
#loader {
  z-index: 1;
  position: fixed;
  top: $top + 20px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  margin: 100px auto;
}
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 1.4s infinite ease-in-out;
  animation: sk-bounce 1.4s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>
