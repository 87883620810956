import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import ManagerRoutes from "./manager.js";

const Login = () => import(/* webpackChunkName: "Login" */ "@/views/Login.vue");
const ForgotPassword = () =>
  import(/* webpackChunkName: "ForgotPassword" */ "@/views/ForgotPassword.vue");
const Register = () =>
  import(/* webpackChunkName: "Register" */ "@/views/Register.vue");
const Validate = () =>
  import(/* webpackChunkName: "Validate" */ "@/views/validate/Validate.vue");
const WithId = () =>
  import(/* webpackChunkName: "WithId" */ "@/views/validate/WithId.vue");
const ResetPassword = () =>
  import(
    /* webpackChunkName: "ResetPassword" */ "@/views/validate/ResetPassword.vue"
  );

const Thanks = () =>
  import(/* webpackChunkName: "Thanks" */ "@/views/Thanks.vue");
const ArtistProfile = () =>
  import(/* webpackChunkName: "ArtistProfile" */ "@/views/ArtistProfile.vue");
const GroupRegistration = () =>
  import(
    /* webpackChunkName: "GroupRegistration" */ "@/views/GroupRegistration.vue"
  );
const RehearsalSpaces = () =>
  import(
    /* webpackChunkName: "RehearsalSpaces" */ "@/views/RehearsalSpaces.vue"
  );
const Booking = () =>
  import(/* webpackChunkName: "Booking" */ "@/views/Booking.vue");
const UserBookings = () =>
  import(/* webpackChunkName: "UserBookings" */ "@/views/UserBookings.vue");
const SearchSlotResults = () =>
  import(
    /* webpackChunkName: "SearchSlotResults" */ "@/views/SearchSlotResults.vue"
  );
const Notifications = () =>
  import(/* webpackChunkName: "Notifications" */ "@/views/Notifications.vue");
const TOS = () => import(/* webpackChunkName: "TOS" */ "@/views/TOS.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: RehearsalSpaces,
    name: "RehearsalSpaces",
  },
  {
    path: "/s/:slug",
    component: () =>
      import(/* webpackChunkName: "ViewSpace" */ "@/views/Space/ViewSpace.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "SpaceSections" */ "@/views/Space/Sections.vue"
          ),
        name: "Space-Sections",
      },
      {
        path: "photos",
        component: () =>
          import(/* webpackChunkName: "Photos" */ "@/views/Space/Photos.vue"),
        name: "Photos",
      },
    ],
  },

  {
    path: "/u/",
    component: () => import(/* webpackChunkName: "User" */ "@/views/User.vue"),
    meta: { requiresAuth: true, requiresManagerAuth: true },
    children: [
      {
        path: "artist",
        component: ArtistProfile,
        // meta: { requiresAuth: true },
        name: "ArtistProfile",
      },
      {
        path: "group-r",
        component: GroupRegistration,
        name: "GroupRegistration",
        meta: { requiresAuth: true },
      },
      {
        path: "notifications",
        component: Notifications,
        name: "Notifications",
        meta: { requiresAuth: true },
      },
      {
        path: "user-bookings",
        component: UserBookings,
        name: "UserBookings",
        meta: { requiresAuth: true },
      },
      {
        path: "user-bookings/:ID",
        component: UserBookings,
        name: "UserBookingsID",
        meta: { requiresAuth: true },
      },
    ],
  },
  { path: "/search", component: SearchSlotResults, name: "SearchSlotResults" },
  { path: "/tos/:slug", component: TOS, name: "TOS" },
  { path: "/login", component: Login, name: "Login" },
  { path: "/reg", component: Register, name: "Register" },
  {
    path: "/val",
    component: Validate,
    name: "Validate",
    children: [
      { path: "vwid/:valId", component: WithId, name: "ValidateWithId" },
      { path: "rp/:valId", component: ResetPassword, name: "ResetPassword" },
    ],
  },
  { path: "/fp", component: ForgotPassword, name: "ForgotPassword" },
  { path: "/thanks/", component: Thanks, name: "Thanks" },
  { path: "/booking/:slug/:DateTime", component: Booking, name: "BookingTime" },
  { path: "/booking/:slug/", component: Booking, name: "Booking" },
  {
    path: "/m/",
    component: () =>
      import(/* webpackChunkName: "Thanks" */ "@/views/Manager/Manager.vue"),
    children: ManagerRoutes,
    meta: { requiresAuth: true, requiresManagerAuth: true },
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: 'Page404' */ "../views/Page404.vue"),
    name: "Page404",
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  store.dispatch("General/updateHideNavigation", true);
  store.dispatch("General/updateLoading", false);
  setTimeout(() => {
    store.dispatch("General/updateLoading", false);
  }, 500);
});

router.beforeEach((to, from, next) => {
  store.dispatch("General/updateLoading", true);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.Auth.loggedIn) {
      const query = {
        p: to.path,
        ...to.query,
      };
      const stringQuery = JSON.stringify(query);
      localStorage.setItem("fullPath", stringQuery);
      next({ name: "Login" });
    } else {
      // if (to.matched.some(record => record.meta.requiresAuth)) {
      // console.log(to.meta)
      // console.log(store.state.Manager)
      // }
      next();
    }
  } else {
    next();
  }
});

export default router;
