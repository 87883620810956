<script>
import Logo from "@/assets/img/logo.png";

export default {
  name: "Navigation",
  props: ["logout", "alert_message"],
  data() {
    return {
      logo: Logo,
    };
  },
  computed: {
    hideNav: {
      get() {
        return this.$store.state.General.hideNavigation;
      },
      set(val) {
        this.$store.dispatch("General/updateHideNavigation", val);
      },
    },
    name() {
      const firstName = this.$store.state.Auth?.authData.first_name;
      const lastName = this.$store.state.Auth?.authData.last_name;
      const params = [];
      if (firstName) {
        params.push(firstName);
      }
      if (lastName) {
        params.push(lastName);
      }
      if (params.length) {
        return params.join(" ");
      }
      return this.$store.state.Auth?.authData?.email;
    },
    isManager() {
      return this.$store.state.Auth?.authData?.is_manager;
    },
    loggedIn() {
      return this.$store.state.Auth.loggedIn;
    },
    unseen_notif() {
      return this.$store.getters["General/getUnseen"];
    },
  },
};
</script>

<template>
  <div id="mainNavigation">
    <link
      href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1"
      rel="stylesheet"
      type="text/css"
    />
    <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-white">
      <div id="mainAlert">
        <div
          class="container bg-warning wow fadeInDown animated"
          data-wow-delay="0.3s"
          style="
            visibility: visible;
            -webkit-animation-delay: 0.3s;
            -moz-animation-delay: 0.3s;
            animation-delay: 0.3s;
          "
        >
          <div class="row justify-content-center">
            <div class="col-md-6 col-md-offset-3 text-center">
              <div class="text-center click">
                {{ alert_message }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <a class="navbar-brand" href="/">
          <img alt="" :src="logo" />
        </a>
        <router-link
          v-if="isManager"
          class="nav-link d-md-none"
          id="managerShortcutCog"
          :to="{ name: 'Manager-LandingChooser' }"
        >
          <i class="fas fa-user-cog"></i>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          @click.prevent="hideNav = !hideNav"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          :class="{ collapse: hideNav }"
          class="navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mr-auto"></ul>
          <ul class="navbar-nav my-2 my-lg-0">
            <li v-if="isManager" class="nav-item bg-warning" id="manager_view">
              <router-link
                class="nav-link"
                :to="{ name: 'Manager-LandingChooser' }"
              >
                <i class="fas fa-user-cog"></i> Go to Manager View
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'RehearsalSpaces' }">
                <i class="far fa-eye"></i> All Spaces
              </router-link>
            </li>

            <template v-if="loggedIn">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'UserBookings' }">
                  <i class="fas fa-list-ul"></i>
                  My Bookings
                </router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'Notifications' }">
                  <span :class="unseen_notif.length > 0 ? 'unseen' : ''">
                    <i class="fas fa-bell"></i>
                    Notifications
                    <!-- <div> <i class="fas fa-circle text-danger"></i> </div> -->
                    <span class="counter" v-if="unseen_notif.length">
                      {{ unseen_notif.length }}
                    </span>
                  </span>
                </router-link>
              </li>

              <li class="nav-item dropdown">
                <router-link class="nav-link" :to="{ name: 'ArtistProfile' }">
                  <i class="fas fa-users"></i> {{ name }}
                </router-link>
              </li>

              <li class="nav-item dropdown">
                <a
                  class="nav-link btn btn-danger bg-danger text-white"
                  @click.prevent="logout"
                >
                  <i class="fas fa-sign-out-alt"></i>
                  Log Out
                </a>
              </li>
            </template>

            <template v-else>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'Login' }">
                  <i class="fas fa-sign-in-alt"></i>
                  Login
                </router-link>
              </li>
              <!-- <li class="nav-item"> -->
              <!-- <router-link class="nav-link" :to="{name: 'Register'}"> -->
              <!--     <i class="fas fa-pen-square"></i> -->
              <!--     Register -->
              <!-- </router-link> -->
              <!-- </li> -->
            </template>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.unseen {
  font-weight: bold;
}
.counter {
  background: rgb(76, 175, 80);
  border-radius: 3px;
  color: white;
  padding: 3px;
}
#managerShortcutCog {
  background-color: rgb(131, 150, 185);
  color: white;
  border-radius: 3px;
  padding: 0.5rem 1rem;
}
#mainNavigation {
  position: relative;
  z-index: 3;
  img {
    max-width: 100px;
  }
  #manager_view {
  }
  #mainAlert {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 20;
    display: none;
    .container {
      background: rgb(255, 193, 7);
      box-shadow: 0px 1px 3px #dea600;
    }
    .click {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 1px solid #eee;
    .nav-item {
      margin-left: 5px;
      margin-bottom: 5px;
      .nav-link {
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
      }
    }
    .dropdown {
      .nav-link {
        border-radius: 0px;
      }
    }
  }
}
</style>
