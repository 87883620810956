import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from "vue-toast-notification";

require("./assets/css/fullcalendar.min.css");
require("bootstrap/dist/css/bootstrap.min.css");
require("@fortawesome/fontawesome-free/css/all.css");
require("vue-toast-notification/dist/theme-sugar.css");
import "vue-select/dist/vue-select.css";

Vue.use(VueToast, { position: "top-right" });
Object.defineProperty(Vue.prototype, "$toast", { value: VueToast });
Vue.config.productionTip = false;

const token = localStorage.getItem("tiziAuthToken");

function createVue() {
  window.vueInstance = new Vue({
    router,
    store,
    render: (h) => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  }).$mount("#app");
}

function init() {
  if (token) {
    const promises = [];
    promises.push(store.dispatch("Auth/updateToken", token));
    promises.push(store.dispatch("Auth/tryLogin", token));
    Promise.all(promises)
      .catch((err) => {
        alert("Error loading app.");
        console.error(err);
      })
      .then(() => {
        createVue();
      });
  } else {
    createVue();
  }
}

init();
